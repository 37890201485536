import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { EmailBannerTemplate } from '../../../shared'

export default () => (
  <StaticQuery
    query={graphql`
      query EmailBannerQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            emailBannerHeading
            emailBannerSubheading
          }
        }
      }
    `}
    render={data => <EmailBannerTemplate data={data} />}
  />
)
