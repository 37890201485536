const randomNum = max => {
  const random = Math.random() * max
  return Math.floor(random)
}

export const selectAmazonLink = urlList => {
  const urlCount = urlList.length
  const randomIdx = randomNum(urlCount)
  return urlList[randomIdx]
}
