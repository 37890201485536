import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { media } from '../../../shared/utils/style-utils'
import { selectAmazonLink } from '../../../shared/utils/amazon-links'
import { H2, P, GreenButton } from '../../../shared'

import Stars50 from '../img/rating-stars/5.0.png'
import Stars45 from '../img/rating-stars/4.5.png'
import Stars40 from '../img/rating-stars/4.0.png'
import Stars35 from '../img/rating-stars/3.5.png'
import Stars30 from '../img/rating-stars/3.0.png'
import Stars25 from '../img/rating-stars/2.5.png'
import Stars20 from '../img/rating-stars/2.0.png'
import Stars15 from '../img/rating-stars/1.5.png'
import Stars10 from '../img/rating-stars/1.0.png'

import TrophyIcon from '../img/top-rated-icon.png'
import AwardIcon from '../img/best-value-icon.png'
import AmazonLogo from '../img/amazon-logo-white.png'
import DownIcon from '../img/down-caret.png'

const Row = styled.div`
  background-color: ${props => props.theme.colors.light};
  border: ${({ bestValue, topRated, theme }) =>
    topRated
      ? `2px solid ${theme.colors.topRated};`
      : bestValue
      ? `2px solid ${theme.colors.bestValue};`
      : `1px solid ${theme.colors.border}};`};
  width: 100%;
  display: grid;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
  ${media.small`
    padding-bottom: 20px;
  `};
  ${media.large`
    grid-template-columns: ${props => props.tableSpacing};
    padding: 22px 0;
    min-height: 238px;
  `};
`

const Badge = styled.img`
  position: absolute;
  ${media.small`
    height: 80px;
    width: 80px;
    top: 6px;
    left: 6px;
  `}
  ${media.medium`
    height: 125px;
    width: 125px;
    top: 16px;
    left: 12px;
  `}
  ${media.large`
    height: 110px;
    width: 110px;
    top: 37px;
    left: -55px;
  `}
`

const TextBox = styled.div`
  height: 100%;
  font-family: ${props =>
    props.isBold ? props.theme.fonts.boldPrimary : props.theme.fonts.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  &:nth-of-type(1),
  &:nth-of-type(2) {
    padding: 0 11px;
    ${media.small`
      border: none;
    `}
    ${media.medium`
      margin: 0;
      padding: 4px 28px;
    `}
    ${media.large`
      border-left: 1px solid ${props => props.theme.colors.border};
    `}
  }
  ${media.small`
    margin: 20px 11px 0 11px;
    border-top: 1px solid ${props => props.theme.colors.border};
    justify-content: ${props => (props.visible ? 'flex-start' : 'center')};
  `}
  ${media.medium`    
    margin: 20px 28px 0 28px;
    padding: 0;
  `}
  ${media.large`
    margin: 0;
    line-height: 30px;
    padding: 0 24px;
    border-top: none;
    border-left: 1px solid ${props => props.theme.colors.border};
  `};
`

const ProductBox = styled(TextBox)`
  flex-direction: column;
  ${media.large`
    height: calc(100% + 78px);
  `}
`

const StyledButton = styled(GreenButton)`
  width: fit-content;
  color: #fff;
  ${media.small`
    margin-top: 12px;
    margin-bottom: 24px !important;
    padding: 15px 30px 12px 30px !important;
    min-height: 57px;
  `}
  ${media.large`
    padding: 12px 24px 12px 24px !important;
    margin-top: 17px;
    margin-bottom: 0;
    min-height: 54px;
  `}
`

const AmazonImg = styled.img`
  width: 70px;
  height: auto;
  margin: 9px 0 0 6px;
`

const ProductImgWrapper = styled.div`
  width: auto;
  ${media.small`
    height: 194px;
    margin: 24px auto 20px auto;
  `};
  ${media.large`
    height: 150px;
    margin: 0 auto 18px auto;
  `};
`

const ProductName = styled.p`
  font-size: 16px;
  text-align: center;
  font-family: ${props => props.theme.fonts.primary};
  letter-spacing: 0.02em;
  line-height: 22px;
  margin: 0;
  ${media.medium`
    font-size: 20px;
    margin-bottom: 20px;
  `};
  ${media.large`
    margin: 0;
    font-size: 16px;
  `};
`

const StyledLink = styled(OutboundLink)`
  text-decoration: none;
  padding: 0;
  margin: 0;
  color: ${props => props.theme.colors.dark};
`

const RatingBox = styled(TextBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  ${media.small`
    border-top: 1px solid ${props => props.theme.colors.border};
    padding: 0;
    margin: 16px 11px 0 11px;
  `};
  ${media.medium`
    padding: 20px 0 30px 0;
    margin: 0 0 20px 0 !important;
  `};
  ${media.large`
    padding: 0;
    border-top: none;
    margin: 0 !important;
  `}
`

const OurRating = styled(P)`
  font-family: ${props => props.theme.fonts.boldTitle};
  text-transform: uppercase;
  ${media.small`
    font-size: 16px !important;
  `};
  ${media.large`
    font-size: 12px !important;
  `};
`

const Rating = styled(H2)`
  font-family: ${props => props.theme.fonts.boldTitle};
  letter-spacing: -0.02em;
  margin-top: 6px;
  font-size: 30px !important;
  margin-bottom: 26px;
  ${media.medium`
    margin-bottom: 0;
  `}
`

const RatingStars = styled.img`
  margin-bottom: 14px;
  width: 40%;
  ${media.medium`
    width: 20%;
  `}
  ${media.large`
    width: 75%;
    max-width: 100px;
  `}
`

const MobileLabel = styled(P)`
  font-family: ${props => props.theme.fonts.boldTitle};
  text-transform: uppercase;
  ${media.small`
    margin: 0 ${props => (props.visible ? '50px' : '12px')} 0 5px;
    padding: 16px 0;
    font-size: 16px !important;
  `};
  ${media.medium`
    margin: 0 ${props => (props.visible ? '50px' : '12px')} 0 50px;
    font-size: 20px !important;
  `};
  ${media.large`
    padding: 6px 0;
    display: none;
    font-size: 16px !important;
  `};
`

const CaretDown = styled.img`
  margin: 0;
  width: 15px;
  height: auto;
`

const RatingStarsMap = {
  '5.0': Stars50,
  '4.5': Stars45,
  '4.0': Stars40,
  '3.5': Stars35,
  '3.0': Stars30,
  '2.5': Stars25,
  '2.0': Stars20,
  '1.5': Stars15,
  '1.0': Stars10,
}

const TableRow = ({ product, tableSpacing, amazonButtons }) => {
  const {
    productName,
    image,
    shopUrl,
    tldr,
    pros,
    cons,
    isTopRated: topRated,
    isBestValue: bestValue,
    ratingStars = 4.5,
    rating = 9.4,
  } = product

  const [selectedUrl, setSelectedUrl] = useState(shopUrl[0])
  const [tldrVisible, setTldrVisible] = useState(false)
  const [prosVisible, setProsVisible] = useState(false)
  const [consVisible, setConsVisible] = useState(false)

  useEffect(() => {
    const url = selectAmazonLink(shopUrl)
    setSelectedUrl(url)
  }, [shopUrl])

  const productImage = getImage(image)

  let isMobile = false
  if (typeof window !== `undefined`) {
    const tabletMQ = window.matchMedia('(max-width: 1120px)')
    isMobile = tabletMQ.matches
  }

  const TextRow = ({ visible, setVisible, label, copy }) => (
    <TextBox
      visible={visible}
      isBold={label === 'Key Takeaway'}
      onClick={e => {
        if (isMobile) {
          e.preventDefault()
          setVisible(true)
        }
      }}
    >
      <MobileLabel visible={visible}>{label}</MobileLabel>
      {visible ? copy : <CaretDown src={DownIcon} />}
    </TextBox>
  )

  const RatingBoxComponent = () => (
    <RatingBox>
      <RatingStars src={RatingStarsMap[ratingStars]} alt="Stars" />
      <OurRating>Our Rating:</OurRating>
      <Rating>{rating} / 10</Rating>
    </RatingBox>
  )

  return (
    <StyledLink href={selectedUrl} target="_blank" rel="noopener noreferrer">
      <Row topRated={topRated} bestValue={bestValue} tableSpacing={tableSpacing}>
        {topRated && <Badge src={TrophyIcon} alt="top rated" />}
        {bestValue && <Badge src={AwardIcon} alt="best value" />}
        <ProductBox style={{ border: 'none' }}>
          {image && (
            <ProductImgWrapper>
              <GatsbyImage
                image={productImage}
                alt={productName}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ProductImgWrapper>
          )}
          <ProductName>{productName}</ProductName>
          {amazonButtons ? (
            <StyledButton>
              CHECK PRICE ON
              <AmazonImg src={AmazonLogo} alt="Amazon Logo" />
            </StyledButton>
          ) : (
            <StyledButton>CHECK BEST PRICE</StyledButton>
          )}
        </ProductBox>
        {isMobile && <RatingBoxComponent />}
        <TextRow
          label="Key Takeaway"
          copy={tldr}
          visible={tldrVisible || !isMobile}
          setVisible={setTldrVisible}
        />
        <TextRow
          label="PROS"
          copy={pros}
          visible={prosVisible || !isMobile}
          setVisible={setProsVisible}
        />
        <TextRow
          label="CONS"
          copy={cons}
          visible={consVisible || !isMobile}
          setVisible={setConsVisible}
        />
        {!isMobile && <RatingBoxComponent />}
      </Row>
    </StyledLink>
  )
}

export default TableRow
